:root,
[data-bs-theme=light] {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000000;
    --bs-white: #ffffff;
    --bs-gray: #7E8299;
    --bs-gray-dark: #3F4254;
    --bs-gray-100: #F9F9F9;
    --bs-gray-200: #F4F4F4;
    --bs-gray-300: #E1E3EA;
    --bs-gray-400: #B5B5C3;
    --bs-gray-500: #A1A5B7;
    --bs-gray-600: #7E8299;
    --bs-gray-700: #5E6278;
    --bs-gray-800: #3F4254;
    --bs-gray-900: #181C32;
    --bs-light: #F9F9F9;
    --bs-primary: #7239EA;
    --bs-secondary: #E1E3EA;
    --bs-success: #50cd89;
    --bs-info: #2C9AFF;
    --bs-warning: #E78B2F;
    --bs-danger: #F14C41;
    --bs-dark: #181C32;
    --bs-light-rgb: 249, 249, 249;
    --bs-primary-rgb: 114, 57, 234;
    --bs-secondary-rgb: 225, 227, 234;
    --bs-success-rgb: 80, 205, 137;
    --bs-info-rgb: 44, 154, 255;
    --bs-warning-rgb: 231, 139, 47;
    --bs-danger-rgb: 241, 76, 65;
    --bs-dark-rgb: 24, 28, 50;
    --bs-primary-text: #0a58ca;
    --bs-secondary-text: #7E8299;
    --bs-success-text: #146c43;
    --bs-info-text: #087990;
    --bs-warning-text: #997404;
    --bs-danger-text: #b02a37;
    --bs-light-text: #7E8299;
    --bs-dark-text: #5E6278;
    --bs-primary-bg-subtle: #cfe2ff;
    --bs-secondary-bg-subtle: #F9F9F9;
    --bs-success-bg-subtle: #d1e7dd;
    --bs-info-bg-subtle: #cff4fc;
    --bs-warning-bg-subtle: #fff3cd;
    --bs-danger-bg-subtle: #f8d7da;
    --bs-light-bg-subtle: #fcfcfc;
    --bs-dark-bg-subtle: #B5B5C3;
    --bs-primary-border-subtle: #9ec5fe;
    --bs-secondary-border-subtle: #F4F4F4;
    --bs-success-border-subtle: #a3cfbb;
    --bs-info-border-subtle: #9eeaf9;
    --bs-warning-border-subtle: #ffe69c;
    --bs-danger-border-subtle: #f1aeb5;
    --bs-light-border-subtle: #F4F4F4;
    --bs-dark-border-subtle: #A1A5B7;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 24, 28, 50;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: Inter, Helvetica, "sans-serif";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #181C32;
    --bs-emphasis-color: #000000;
    --bs-emphasis-color-rgb: 0, 0, 0;
    --bs-secondary-color: rgba(24, 28, 50, 0.75);
    --bs-secondary-color-rgb: 24, 28, 50;
    --bs-secondary-bg: #F4F4F4;
    --bs-secondary-bg-rgb: 244, 244, 244;
    --bs-tertiary-color: rgba(24, 28, 50, 0.5);
    --bs-tertiary-color-rgb: 24, 28, 50;
    --bs-tertiary-bg: #F9F9F9;
    --bs-tertiary-bg-rgb: 249, 249, 249;
    --bs-body-bg: #ffffff;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-heading-color: #181C32;
    --bs-link-color: #7239EA;
    --bs-link-color-rgb: 114, 57, 234;
    --bs-link-decoration: none;
    --bs-link-hover-color: #5014D0;
    --bs-link-hover-color-rgb: 80, 20, 208;
    --bs-link-hover-decoration: none;
    --bs-code-color: #b93993;
    --bs-highlight-bg: #fff3cd;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #F4F4F4;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.65rem;
    --bs-border-radius-sm: 0.55rem;
    --bs-border-radius-lg: 1rem;
    --bs-border-radius-xl: 1.25rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
    --bs-box-shadow-sm: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
    --bs-box-shadow-lg: 0 1rem 2rem 1rem rgba(0, 0, 0, 0.1);
    --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-emphasis-color: #000000;
    --bs-form-control-bg: var(--bs-body-bg);
    --bs-form-control-disabled-bg: var(--bs-secondary-bg);
    --bs-highlight-bg: #fff3cd;
    --bs-breakpoint-xs: 0;
    --bs-breakpoint-sm: 576px;
    --bs-breakpoint-md: 768px;
    --bs-breakpoint-lg: 992px;
    --bs-breakpoint-xl: 1200px;
    --bs-breakpoint-xxl: 1400px;
}

[data-bs-theme=dark] {
    --bs-body-color: #FFFFFF;
    --bs-body-color-rgb: 255, 255, 255;
    --bs-body-bg: #1e1e2d;
    --bs-body-bg-rgb: 30, 30, 45;
    --bs-emphasis-color: #F9F9F9;
    --bs-emphasis-color-rgb: 249, 249, 249;
    --bs-secondary-color: rgba(255, 255, 255, 0.75);
    --bs-secondary-color-rgb: 255, 255, 255;
    --bs-secondary-bg: #3F4254;
    --bs-secondary-bg-rgb: 63, 66, 84;
    --bs-tertiary-color: rgba(255, 255, 255, 0.5);
    --bs-tertiary-color-rgb: 255, 255, 255;
    --bs-tertiary-bg: #2c2f43;
    --bs-tertiary-bg-rgb: 44, 47, 67;
    --bs-emphasis-color: #ffffff;
    --bs-primary-text: #6ea8fe;
    --bs-secondary-text: #E1E3EA;
    --bs-success-text: #75b798;
    --bs-info-text: #6edff6;
    --bs-warning-text: #ffda6a;
    --bs-danger-text: #ea868f;
    --bs-light-text: #F9F9F9;
    --bs-dark-text: #E1E3EA;
    --bs-primary-bg-subtle: #031633;
    --bs-secondary-bg-subtle: #181C32;
    --bs-success-bg-subtle: #051b11;
    --bs-info-bg-subtle: #032830;
    --bs-warning-bg-subtle: #332701;
    --bs-danger-bg-subtle: #2c0b0e;
    --bs-light-bg-subtle: #3F4254;
    --bs-dark-bg-subtle: #20212a;
    --bs-primary-border-subtle: #084298;
    --bs-secondary-border-subtle: #5E6278;
    --bs-success-border-subtle: #0f5132;
    --bs-info-border-subtle: #055160;
    --bs-warning-border-subtle: #664d03;
    --bs-danger-border-subtle: #842029;
    --bs-light-border-subtle: #5E6278;
    --bs-dark-border-subtle: #3F4254;
    --bs-heading-color: #FFFFFF;
    --bs-link-color: #7239EA;
    --bs-link-hover-color: #9ec5fe;
    --bs-link-color-rgb: 114, 57, 234;
    --bs-link-hover-color-rgb: 158, 197, 254;
    --bs-code-color: #b93993;
    --bs-border-color: #2B2B40;
    --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
}
.btn-light {
    --bs-btn-color: #000000;
    --bs-btn-bg: #F9F9F9;
    --bs-btn-border-color: #F9F9F9;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #d4d4d4;
    --bs-btn-hover-border-color: #c7c7c7;
    --bs-btn-focus-shadow-rgb: 212, 212, 212;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #c7c7c7;
    --bs-btn-active-border-color: #bbbbbb;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000000;
    --bs-btn-disabled-bg: #F9F9F9;
    --bs-btn-disabled-border-color: #F9F9F9;
}

.btn-primary {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #7239EA;
    --bs-btn-border-color: #7239EA;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #6130c7;
    --bs-btn-hover-border-color: #5b2ebb;
    --bs-btn-focus-shadow-rgb: 135, 87, 237;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #5b2ebb;
    --bs-btn-active-border-color: #562bb0;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #7239EA;
    --bs-btn-disabled-border-color: #7239EA;
}

.btn-secondary {
    --bs-btn-color: #000000;
    --bs-btn-bg: #E1E3EA;
    --bs-btn-border-color: #E1E3EA;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #e6e7ed;
    --bs-btn-hover-border-color: #e4e6ec;
    --bs-btn-focus-shadow-rgb: 191, 193, 199;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #e7e9ee;
    --bs-btn-active-border-color: #e4e6ec;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000000;
    --bs-btn-disabled-bg: #E1E3EA;
    --bs-btn-disabled-border-color: #E1E3EA;
}

.btn-success {
    --bs-btn-color: #000000;
    --bs-btn-bg: #50cd89;
    --bs-btn-border-color: #50cd89;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #6ad59b;
    --bs-btn-hover-border-color: #62d295;
    --bs-btn-focus-shadow-rgb: 68, 174, 116;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #73d7a1;
    --bs-btn-active-border-color: #62d295;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000000;
    --bs-btn-disabled-bg: #50cd89;
    --bs-btn-disabled-border-color: #50cd89;
}

.btn-info {
    --bs-btn-color: #000000;
    --bs-btn-bg: #2C9AFF;
    --bs-btn-border-color: #2C9AFF;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #4ca9ff;
    --bs-btn-hover-border-color: #41a4ff;
    --bs-btn-focus-shadow-rgb: 37, 131, 217;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #56aeff;
    --bs-btn-active-border-color: #41a4ff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000000;
    --bs-btn-disabled-bg: #2C9AFF;
    --bs-btn-disabled-border-color: #2C9AFF;
}

.btn-warning {
    --bs-btn-color: #000000;
    --bs-btn-bg: #E78B2F;
    --bs-btn-border-color: #E78B2F;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #eb9c4e;
    --bs-btn-hover-border-color: #e99744;
    --bs-btn-focus-shadow-rgb: 196, 118, 40;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #eca259;
    --bs-btn-active-border-color: #e99744;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000000;
    --bs-btn-disabled-bg: #E78B2F;
    --bs-btn-disabled-border-color: #E78B2F;
}

.btn-danger {
    --bs-btn-color: #000000;
    --bs-btn-bg: #F14C41;
    --bs-btn-border-color: #F14C41;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #f3675e;
    --bs-btn-hover-border-color: #f25e54;
    --bs-btn-focus-shadow-rgb: 205, 65, 55;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #f47067;
    --bs-btn-active-border-color: #f25e54;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000000;
    --bs-btn-disabled-bg: #F14C41;
    --bs-btn-disabled-border-color: #F14C41;
}

.btn-dark {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #181C32;
    --bs-btn-border-color: #181C32;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #3b3e51;
    --bs-btn-hover-border-color: #2f3347;
    --bs-btn-focus-shadow-rgb: 59, 62, 81;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #46495b;
    --bs-btn-active-border-color: #2f3347;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #181C32;
    --bs-btn-disabled-border-color: #181C32;
}

.btn-outline-light {
    --bs-btn-color: #F9F9F9;
    --bs-btn-border-color: #F9F9F9;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #F9F9F9;
    --bs-btn-hover-border-color: #F9F9F9;
    --bs-btn-focus-shadow-rgb: 249, 249, 249;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #F9F9F9;
    --bs-btn-active-border-color: #F9F9F9;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #F9F9F9;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #F9F9F9;
    --bs-gradient: none;
}

.btn-outline-primary {
    --bs-btn-color: #7239EA;
    --bs-btn-border-color: #7239EA;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #7239EA;
    --bs-btn-hover-border-color: #7239EA;
    --bs-btn-focus-shadow-rgb: 114, 57, 234;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #7239EA;
    --bs-btn-active-border-color: #7239EA;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #7239EA;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #7239EA;
    --bs-gradient: none;
}

.btn-outline-secondary {
    --bs-btn-color: #E1E3EA;
    --bs-btn-border-color: #E1E3EA;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #E1E3EA;
    --bs-btn-hover-border-color: #E1E3EA;
    --bs-btn-focus-shadow-rgb: 225, 227, 234;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #E1E3EA;
    --bs-btn-active-border-color: #E1E3EA;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #E1E3EA;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #E1E3EA;
    --bs-gradient: none;
}

.btn-outline-success {
    --bs-btn-color: #50cd89;
    --bs-btn-border-color: #50cd89;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #50cd89;
    --bs-btn-hover-border-color: #50cd89;
    --bs-btn-focus-shadow-rgb: 80, 205, 137;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #50cd89;
    --bs-btn-active-border-color: #50cd89;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #50cd89;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #50cd89;
    --bs-gradient: none;
}

.btn-outline-info {
    --bs-btn-color: #2C9AFF;
    --bs-btn-border-color: #2C9AFF;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #2C9AFF;
    --bs-btn-hover-border-color: #2C9AFF;
    --bs-btn-focus-shadow-rgb: 44, 154, 255;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #2C9AFF;
    --bs-btn-active-border-color: #2C9AFF;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #2C9AFF;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #2C9AFF;
    --bs-gradient: none;
}

.btn-outline-warning {
    --bs-btn-color: #E78B2F;
    --bs-btn-border-color: #E78B2F;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #E78B2F;
    --bs-btn-hover-border-color: #E78B2F;
    --bs-btn-focus-shadow-rgb: 231, 139, 47;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #E78B2F;
    --bs-btn-active-border-color: #E78B2F;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #E78B2F;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #E78B2F;
    --bs-gradient: none;
}

.btn-outline-danger {
    --bs-btn-color: #F14C41;
    --bs-btn-border-color: #F14C41;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #F14C41;
    --bs-btn-hover-border-color: #F14C41;
    --bs-btn-focus-shadow-rgb: 241, 76, 65;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #F14C41;
    --bs-btn-active-border-color: #F14C41;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #F14C41;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #F14C41;
    --bs-gradient: none;
}

.btn-outline-dark {
    --bs-btn-color: #181C32;
    --bs-btn-border-color: #181C32;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #181C32;
    --bs-btn-hover-border-color: #181C32;
    --bs-btn-focus-shadow-rgb: 24, 28, 50;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #181C32;
    --bs-btn-active-border-color: #181C32;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #181C32;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #181C32;
    --bs-gradient: none;
}

.btn-link {
    --bs-btn-font-weight: 400;
    --bs-btn-color: var(--bs-link-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: var(--bs-link-hover-color);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: var(--bs-link-hover-color);
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: var(--bs-gray-600);
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: none;
    --bs-btn-focus-shadow-rgb: 135, 87, 237;
    text-decoration: none;
}
[data-bs-theme=light] {
    --bs-text-muted: #A1A5B7;
    --bs-gray-100: #F9F9F9;
    --bs-gray-100-rgb: 249, 249, 249;
    --bs-gray-200: #F4F4F4;
    --bs-gray-200-rgb: 244, 244, 244;
    --bs-gray-300: #E1E3EA;
    --bs-gray-300-rgb: 225, 227, 234;
    --bs-gray-400: #B5B5C3;
    --bs-gray-400-rgb: 181, 181, 195;
    --bs-gray-500: #A1A5B7;
    --bs-gray-500-rgb: 161, 165, 183;
    --bs-gray-600: #7E8299;
    --bs-gray-600-rgb: 126, 130, 153;
    --bs-gray-700: #5E6278;
    --bs-gray-700-rgb: 94, 98, 120;
    --bs-gray-800: #3F4254;
    --bs-gray-800-rgb: 63, 66, 84;
    --bs-gray-900: #181C32;
    --bs-gray-900-rgb: 24, 28, 50;
    --bs-light: #F9F9F9;
    --bs-primary: #7239EA;
    --bs-secondary: #E1E3EA;
    --bs-success: #50cd89;
    --bs-info: #2C9AFF;
    --bs-warning: #E78B2F;
    --bs-danger: #F14C41;
    --bs-dark: #181C32;
    --bs-primary-active: #5014D0;
    --bs-secondary-active: #B5B5C3;
    --bs-light-active: #F4F4F4;
    --bs-success-active: #47be7d;
    --bs-info-active: #2884EF;
    --bs-warning-active: #CE7317;
    --bs-danger-active: #DD4339;
    --bs-dark-active: #131628;
    --bs-primary-light: #F8F5FF;
    --bs-secondary-light: #F9F9F9;
    --bs-success-light: #e8fff3;
    --bs-info-light: #F1F4FF;
    --bs-warning-light: #FCF2E8;
    --bs-danger-light: #FFF2F1;
    --bs-dark-light: #F4F4F4;
    --bs-primary-inverse: #FFFFFF;
    --bs-secondary-inverse: #3F4254;
    --bs-light-inverse: #7E8299;
    --bs-success-inverse: #FFFFFF;
    --bs-info-inverse: #FFFFFF;
    --bs-warning-inverse: #FFFFFF;
    --bs-danger-inverse: #FFFFFF;
    --bs-dark-inverse: #ffffff;
    --bs-light-rgb: 249, 249, 249;
    --bs-primary-rgb: 114, 57, 234;
    --bs-secondary-rgb: 225, 227, 234;
    --bs-success-rgb: 80, 205, 137;
    --bs-info-rgb: 44, 154, 255;
    --bs-warning-rgb: 231, 139, 47;
    --bs-danger-rgb: 241, 76, 65;
    --bs-dark-rgb: 24, 28, 50;
    --bs-text-white: #ffffff;
    --bs-text-primary: #7239EA;
    --bs-text-secondary: #E1E3EA;
    --bs-text-light: #F9F9F9;
    --bs-text-success: #50cd89;
    --bs-text-info: #2C9AFF;
    --bs-text-warning: #E78B2F;
    --bs-text-danger: #F14C41;
    --bs-text-dark: #181C32;
    --bs-text-muted: #A1A5B7;
    --bs-text-gray-100: #F9F9F9;
    --bs-text-gray-200: #F4F4F4;
    --bs-text-gray-300: #E1E3EA;
    --bs-text-gray-400: #B5B5C3;
    --bs-text-gray-500: #A1A5B7;
    --bs-text-gray-600: #7E8299;
    --bs-text-gray-700: #5E6278;
    --bs-text-gray-800: #3F4254;
    --bs-text-gray-900: #181C32;
    --bs-border-color: #F4F4F4;
    --bs-border-dashed-color: #E1E3EA;
    --bs-component-active-color: #FFFFFF;
    --bs-component-active-bg: #7239EA;
    --bs-component-hover-color: #7239EA;
    --bs-component-hover-bg: #F9F9F9;
    --bs-component-checked-color: #FFFFFF;
    --bs-component-checked-bg: #7239EA;
    --bs-box-shadow-xs: 0 0.1rem 0.75rem 0.25rem rgba(0, 0, 0, 0.05);
    --bs-box-shadow-sm: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
    --bs-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
    --bs-box-shadow-lg: 0 1rem 2rem 1rem rgba(0, 0, 0, 0.1);
    --bs-input-bg: var(--bs-body-bg);
    --bs-input-color: var(--bs-gray-700);
    --bs-input-solid-color: var(--bs-gray-700);
    --bs-input-solid-bg: var(--bs-gray-100);
    --bs-input-solid-bg-focus: var(--bs-gray-200);
    --bs-input-solid-placeholder-color: var(--bs-gray-500);
    --bs-tooltip-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    --bs-table-striped-bg: rgba(var(--bs-gray-100-rgb), 0.75);
    --bs-table-loading-message-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    --bs-dropdown-bg: var(--bs-body-bg);
    --bs-dropdown-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    --bs-code-bg: #F1F3F8;
    --bs-code-box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.08);
    --bs-code-color: #b93993;
    --bs-symbol-label-color: var(--bs-gray-800);
    --bs-symbol-label-bg: var(--bs-gray-100);
    --bs-symbol-border-color: rgba(var(--bs-body-bg), 0.5);
    --bs-bullet-bg-color: var(--bs-gray-400);
    --bs-scrolltop-opacity: 0;
    --bs-scrolltop-opacity-on: 0.3;
    --bs-scrolltop-opacity-hover: 1;
    --bs-scrolltop-box-shadow: var(--bs-box-shadow);
    --bs-scrolltop-bg-color: var(--bs-primary);
    --bs-scrolltop-bg-color-hover: var(--bs-primary);
    --bs-scrolltop-icon-color: var(--bs-primary-inverse);
    --bs-scrolltop-icon-color-hover: var(--bs-primary-inverse);
    --bs-drawer-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.05);
    --bs-drawer-bg-color: #ffffff;
    --bs-drawer-overlay-bg-color: rgba(0, 0, 0, 0.2);
    --bs-menu-dropdown-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    --bs-menu-dropdown-bg-color: var(--bs-body-bg);
    --bs-menu-heading-color: #A1A5B7;
    --bs-menu-link-color-hover: #7239EA;
    --bs-menu-link-color-show: #7239EA;
    --bs-menu-link-color-here: #7239EA;
    --bs-menu-link-color-active: #7239EA;
    --bs-menu-link-bg-color-hover: #F9F9F9;
    --bs-menu-link-bg-color-show: #F9F9F9;
    --bs-menu-link-bg-color-here: #F9F9F9;
    --bs-menu-link-bg-color-active: #F9F9F9;
    --bs-scrollbar-color: #F4F4F4;
    --bs-scrollbar-hover-color: #efefef;
    --bs-scrollbar-size: 5px;
    --bs-scrollbar-overlay-size: 19px;
    --bs-scrollbar-overlay-space: 7px;
    --bs-overlay-bg: rgba(0, 0, 0, 0.05);
    --bs-blockui-overlay-bg: rgba(0, 0, 0, 0.05);
    --bs-rating-color-default: #B5B5C3;
    --bs-rating-color-active: #FFAD0F;
    --bs-ribbon-label-box-shadow: 0px -1px 5px 0px rgba(24, 28, 50, 0.1);
    --bs-ribbon-label-bg: #7239EA;
    --bs-ribbon-label-border-color: #4312ab;
    --bs-ribbon-clip-bg: #181C32;
    --bs-engage-btn-bg: #ffffff;
    --bs-engage-btn-box-shadow: 0px 0px 22px #E0E0E0;
    --bs-engage-btn-border-color: #E8E8E8;
    --bs-engage-btn-color: #3F4254;
    --bs-engage-btn-icon-color: #7E8299;
    --bs-engage-btn-color-active: #3F4254;
}