/*

.alert-danger {
    color: #fff;
    background-color: #df4759;
    border-color: #df4759;
}
*/
.terms-of-service {
    margin: 30px;
    padding: 50px;
    font-size: 11pt;
    line-height: 19.425px;
    font-family: Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif;
}

.port-nav {
    float: left;
    display: contents;
}

.btn-group {
    height: auto;
    display: inline-flex;
    position: relative;
    width: 25%;
    vertical-align: middle;
}

.btn-inh {
    height: 44px;
    font-size: 13px;
    font-weight: bold;
}

    .btn-inh > i {
        font-size: 13px !important;
    }

    .btn-inh > span.icon-text {
        padding-left: 5px;
        font-size: 0.9rem;
        display: inline-block;
        font-weight: 500;
    }


.btn-inh {
    color: black;
    width: 100px;
    background-color: white;
    box-shadow: -1px 1px 5px black;
    margin-right: 10px;
    border-radius: 10% !important;
}

.icon-text {
    display: block;
    font-weight: 600;
    color: var(--bs-gray-600) !important;
}

.btn-inh:hover {
    background-color: #dce8f3;
    border-color: #d3e2f0;
    color: #28a8e2;
}

.bg-gray {
    background-color: #f1f4f8 !important;
}

body {
    margin: 0;
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 1.6;
    color: #161c2d;
    text-align: left;
    background-color: #fff;
    font-family: Inter, Helvetica, "sans-serif";
    overflow: auto;
}

nav ul a {
    color: var(--bs-gray-600) !important;
    font-weight: 600;
    font-size: .8rem;
}

a.nav-link {
    color: black !important;
}

.currentMenuItem {
    padding: 15px;
}

.borderNoneCard {
    border: none !important;
}

a {
    color: #28a8e2;
    text-decoration: none;
}

.menuOptions {
    float: right;
    font-size: 25px;
    color: #59b87d;
}

#searchPDFInput {
    /*width: 250px !important;*/
    min-width: 300px;
    float: right;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.h6.text-uppercase, h6.text-uppercase {
    letter-spacing: .08em;
}

.card-body, .card-footer, .card-img, .card-img-left, .card-img-right, .card-img-top, .card-meta {
    position: relative;
    min-height: 1px;
}

.card-body {
    display: block;
    flex-grow: 0;
}

.list {
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
}

.text-gray-700 {
    color: #506690 !important;
}

.card-list .active .list-link::before {
    display: block;
}

.card-list .list-link::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1rem;
    border-right: 2px solid #59b87d;
    display: none;
}

*, ::after, ::before {
    box-sizing: border-box;
}

.card-list .active .list-link {
    background-color: white;
}

.list-link {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-size: .9375rem;
    color: #506690;
    margin-right: 1rem;
    margin-left: 1rem;
}

.card-list .list-link {
    position: relative;
}

body {
    margin: 0;
    font-family: url(./SFProText-Regular.ttf);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    outline: none;
    transition: border .24s ease-in-out;
}

section.upload {
    height: 100%;
    position: relative;
    width: 100%;
}

form#newform {
    min-width: 35%;
    width: 550px;
}

.mainCurrentAccountPicture {
    display: block;
    overflow: hidden;
    width: 30px;
    border-radius: 50%;
    height: 30px;
    text-align: center;
    align-items: center;
    border: 0.5px solid black;
}

    .mainCurrentAccountPicture .userCharacterStyle {
        font-weight: bold;
        font-size: 15px;
        color: blue;
    }


.currentAccountPicture {
    display: block;
    border-radius: 50%;
    overflow: hidden;
    width: 90px;
    min-width: 80px;
    height: 90px;
    text-align: center;
    align-items: center;
    margin-right: 15px;
    border: 0.5px solid black;
    font-size: 35px;
    font-weight: 600;
    font-family: "Font Awesome 6 Free";
    /*background-color: #0C3B5E;*/
}

    .currentAccountPicture:hover::before {
        font-family: "Font Awesome 6 Free";
        color: white;
        content: "\f083";
        z-index: 1;
        position: absolute;
        display: block;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        overflow: hidden;
        left: 10px;
        top: 45px;
        padding-top: 15px;
    }

    .currentAccountPicture::before {
        background-color: rgba(0,0,0,.5);
    }


.content {
    width: 95%;
    max-width: 700px;
    margin: 2rem auto;
}

.c-btn {
    padding: 0.1em 0.5em;
    color: #4285f4;
    background: #e2edff;
    border: 2px solid #bad2fa;
    border-radius: 1em;
    cursor: pointer;
    transition: background 0.15s ease-out;
}

c-btn:hover {
    background: #cbddfb;
}

c-btn:focus {
    outline: 0;
    box-shadow: 0 0 4px currentColor;
}

.c-modal-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    transform: translateZ(0);
    background-color: rgba(0,0,0,0.4) !important;
}

.c-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2.5em 1.5em 1.5em 1.5em;
    background-color: #ffffff;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

@media screen and (min-width: 500px) {

    .c-modal {
        left: 50%;
        top: 50%;
        height: auto;
        transform: translate(-50%, -50%);
        max-width: 30em;
        max-height: calc(100% - 1em);
    }
}

.c-modal__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5em;
    line-height: 1;
    background: #f6f6f7;
    border: 0;
    box-shadow: 0;
    cursor: pointer;
}

.c-modal__close-icon {
    width: 25px;
    height: 25px;
    fill: transparent;
    stroke: black;
    stroke-linecap: round;
    stroke-width: 2;
}

.c-modal__body {
    padding-top: 0.25em;
}

.wrapper-share {
    margin-left: calc(var(--bs-app-sidebar-width) + var(--bs-app-sidebar-gap-start, 0px) + var(--bs-app-sidebar-gap-end, 0px)) !important;
}

.u-hide-visually {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}

.u-lock-scroll {
    overflow: hidden !important;
}

.alert-secondary {
    color: #fff;
    background-color: #506690;
    border-color: #506690;
}

.form-control-calender {
    border-radius: 0.375rem 0px 0px 0.375rem !important
}

.btn-success {
    color: white !important;
}

.font-w-6 {
    font-weight: 600;
}

#scaleSelect {
    border-radius: 5px;
    /* width: 125px; */
    /*border: 1px solid white;*/
    height: 30px;
}

#homebut {
    margin-left: auto;
    /* vertical-align: middle; */
    margin: 5px;
    margin-left: 35px;
}

.App {
    background-color: rgb(255 255 255 / 0%);
    /*color: #fff;*/
    border: 1px solid #ced4da !important;
    border-radius: 15px !important;
    padding: 10px;
}

.toolbarButton {
    max-height: 30px;
    max-width: 50px;
    border: 0.5px solid black;
    border-radius: 10px;
}

.pipe {
    border-left: 1px solid #ccc;
    height: 30px;
}

.toolbarField {
    max-height: 30px;
}

.dropdownToolbarButton {
    max-height: 30px;
}

.listPage-mainDiv {
    background-color: inherit !important;
    width: 100%;
}

.logout {
    float: right;
    font-size: 14px;
    margin-left: auto;
}

.mainMenuLink {
    font-size: 13px;
}

.mainMenuIcon {
    color: white;
    font-size: 30px;
    margin-left: auto;
    margin: 0 auto;
}

.sidebarLoadingSpan {
    background-color: red;
    padding: 0.50rem;
    margin-left: 1rem;
    border-radius: 10px;
}

.app-sidebar {
    position: fixed;
    z-index: 105;
    top: 48px;
    bottom: 0;
    left: 0;
}

@media (min-width: 992px) {
    .app-sidebar {
        display: flex;
        flex-shrink: 0;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    :root {
        --bs-app-sidebar-width: 220px;
        --bs-app-sidebar-width-actual: 220px;
        --bs-app-sidebar-gap-start: 20px;
        --bs-app-sidebar-gap-end: 0px;
        --bs-app-sidebar-gap-top: 0px;
        --bs-app-sidebar-gap-bottom: 0px;
        --bs-app-sidebar-toggled-width: 80px;
        --bs-app-sidebar-toggled-width-actual: 80px;
    }

    [data-kt-app-sidebar-stacked=true] {
        --bs-app-sidebar-width: calc(var(--bs-app-sidebar-primary-width) + var(--bs-app-sidebar-secondary-width, 0px));
    }

    [data-kt-app-sidebar-minimize=on] {
        --bs-app-sidebar-width: 75px;
        --bs-app-sidebar-gap-start: 0px;
        --bs-app-sidebar-gap-end: 0px;
        --bs-app-sidebar-gap-top: 0px;
        --bs-app-sidebar-gap-bottom: 0px;
    }

    [data-kt-app-sidebar-sticky=on] {
        --bs-app-sidebar-width: 300px;
        --bs-app-sidebar-gap-start: 0px;
        --bs-app-sidebar-gap-end: 0px;
        --bs-app-sidebar-gap-top: 0px;
        --bs-app-sidebar-gap-bottom: 0px;
    }

    [data-kt-app-sidebar-collapse=on] {
        --bs-app-sidebar-width: 0px;
    }

    [data-kt-app-sidebar-static=true] .app-sidebar {
        position: relative;
    }

    [data-kt-app-sidebar-offcanvas=true] .app-sidebar {
        display: none;
    }

    [data-kt-app-sidebar-fixed=true] .app-sidebar {
        position: fixed;
        z-index: 105;
        top: 0;
        bottom: 0;
        left: 0;
    }

    [data-kt-app-sidebar-stacked=true] .app-sidebar {
        align-items: stretch;
    }

    [data-kt-app-sidebar-sticky=on] .app-sidebar {
        position: fixed;
        transition: width 0.3s ease;
        top: auto;
        bottom: auto;
        left: auto;
        z-index: 105;
        box-shadow: var(--bs-app-sidebar-sticky-box-shadow);
        border-left: var(--bs-app-sidebar-sticky-border-start);
        border-right: var(--bs-app-sidebar-sticky-border-end);
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    [data-kt-app-sidebar-minimize=on] .app-sidebar {
        transition: width 0.3s ease;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    [data-kt-app-sidebar-hoverable=true] .app-sidebar .app-sidebar-wrapper {
        width: var(--bs-app-sidebar-width-actual);
    }

    [data-kt-app-sidebar-hoverable=true][data-kt-app-sidebar-minimize=on] .app-sidebar:hover:not(.animating) {
        transition: width 0.3s ease;
        width: var(--bs-app-sidebar-width-actual);
    }

    [data-kt-app-sidebar-collapse=on] .app-sidebar {
        transition: width 0.3s ease;
        width: var(--bs-app-sidebar-width-actual);
        margin-left: calc(-1 * var(--bs-app-sidebar-width-actual));
    }

    [data-kt-app-sidebar-minimize=on] .app-sidebar-minimize-d-none {
        display: none !important;
    }

    [data-kt-app-sidebar-minimize=on] .app-sidebar-minimize-d-flex {
        display: flex !important;
    }

    [data-kt-app-sidebar-sticky=on] .app-sidebar-sticky-d-none {
        display: none !important;
    }

    [data-kt-app-sidebar-sticky=on] .app-sidebar-sticky-d-flex {
        display: flex !important;
    }

    [data-kt-app-sidebar-collapse=on] .app-sidebar-collapse-d-none {
        display: none !important;
    }

    [data-kt-app-sidebar-collapse=on] .app-sidebar-collapse-d-flex {
        display: flex !important;
    }

    [data-kt-app-sidebar-fixed=true][data-kt-app-header-fixed=true]:not([data-kt-app-sidebar-push-header=true]) .app-sidebar {
        top: var(--bs-app-header-height);
    }

    [data-kt-app-sidebar-fixed=true][data-kt-app-header-fixed=true][data-kt-app-toolbar-fixed=true]:not([data-kt-app-sidebar-push-toolbar=true]) .app-sidebar {
        top: calc(var(--bs-app-header-height) + var(--bs-app-toolbar-height, 0px));
    }
}

.app-wrapper:not(.toggled) {
    margin-left: calc(var(--bs-app-sidebar-width) + var(--bs-app-sidebar-gap-start, 0px) + var(--bs-app-sidebar-gap-end, 0px));
    overflow-x: auto;
    overflow-y: initial;
}

.app-wrapper.toggled {
    margin-left: calc(var(--bs-app-sidebar-toggled-width) + var(--bs-app-sidebar-gap-start, 0px) + var(--bs-app-sidebar-gap-end, 0px));
    overflow-x: auto;
    overflow-y: initial;
}

.mainDivRsT {
    display: flex;
    width: 100%;
    position: fixed;
    /*position: relative;*/
    /*margin-top: 64px;*/
    margin-top: 48px;
    height: 100%;
    overflow: auto;
}

.BaseNineDotMenu {
    position: absolute;
    z-index: 1000000;
    background-color: #fff;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    box-sizing: border-box;
    outline: none;
    overflow-y: auto;
    visibility: visible;
    pointer-events: auto;
    top: 0;
    left: 0;
    right: auto;
    height: 100vh;
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-duration: .18s;
    animation-duration: .18s;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    box-shadow: 0 24px 54px rgba(0,0,0,.15), 0 4.5px 13.5px rgba(0,0,0,.08);
}

/*#sharePage {
    min-width: 500px;
}*/
/*
#sharePage{
    max-width: 85%;
}
*/
section#sharePage form {
    padding: 15px;
    padding-bottom: 0px;
}

.shareTable {
    padding: 15px;
    border-bottom: 1px solid #ececec;
    border-left: 1px solid #ededed;
    border-right: 1px solid #efefef;
    border-top: 1px solid #ececec;
    background: rgba(250,250,250,0.5);
    position: relative;
    border-radius: 10px;
    margin-bottom: 1rem;
    min-height: 410px;
}

.msSidebar {
    /*
    border-right: 1px solid #eaeaea;
    */
    display: flex;
    flex-flow: column;
    flex: 0 0 auto;
    overflow: auto;
    box-sizing: border-box;
    background: rgb(246, 246, 246);
    color: rgb(126, 130, 153);
    height: 100%;
    width: 216px;
    min-width: 216px;
    transition: width 0s ease 0s, left 0s ease 0s, right 0s ease 0s, all 0.3s ease 0s;
}

.btn.list-link:hover {
    background-color: #d9d7d5;
}
/*
.btn.list-link {
    margin-left: 1.25rem;
}
*/
.col-auto {
    margin-left: auto;
    padding: 8px;
}

.Toastify__toast-container--top-right {
    top: auto;
}

.baseNavbar {
    position: fixed;
    z-index: 999;
    width: 100%;
    background-color: white !important;
    border-bottom: 1px solid #ced4da;
}

.navbar {
    opacity: 1 !important;
    height: 48px;
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background-color: #fff;
    max-height: 110vh;
    max-width: 120vh;
}

.modal-footer-spec {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
}

.modal-header .modal-footer {
    padding: 10px;
}

.modal-title {
    margin: 0;
}

.modal-body {
    padding: 10px;
    border-top: 1px solid #eee;
}

#container {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    height: 400px;
    background-color: #fff;
}

a.active {
    background-color: white;
    color: #fff;
    box-shadow: 0 24px 54px rgba(0,0,0,.15), 0 4.5px 13.5px rgba(0,0,0,.08);
}

.myDocument {
    background-color: rgb(255 255 255 / 0%);
    color: #fff;
    border: 1px solid #ced4da !important;
    border-radius: 15px !important;
    padding: 10px;
    min-height: 280px;
    font-size: 17px;
}

/*

#root:has(.mainDivRsT):has(#sharePage) {
    overflow: auto;
}
*/
.badge-light-success {
    color: var(--bs-success);
    background-color: var(--bs-success-light);
}

.badge {
    display: inline-flex;
    align-items: center;
}

button:disabled:not(#pagination-first-page):not(#pagination-previous-page):not(#pagination-next-page):not(#pagination-last-page) {
    background-color: #f4f4f4 !important;
}

.image_btn {
    transition: opacity .2s ease-in-out;
    background-color: rgba(32,33,36,.6);
    bottom: 0;
    height: 33%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
}

.img_sub_div {
    background-image: url(https://www.gstatic.com/images/icons/material/system/2x/photo_camera_white_24dp.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    height: 100%;
    opacity: .8;
}

[data-bs-theme=light] {
    --bs-text-muted: #A1A5B7;
    --bs-gray-100: #F9F9F9;
    --bs-gray-100-rgb: 249, 249, 249;
    --bs-gray-200: #F4F4F4;
    --bs-gray-200-rgb: 244, 244, 244;
    --bs-gray-300: #E1E3EA;
    --bs-gray-300-rgb: 225, 227, 234;
    --bs-gray-400: #B5B5C3;
    --bs-gray-400-rgb: 181, 181, 195;
    --bs-gray-500: #A1A5B7;
    --bs-gray-500-rgb: 161, 165, 183;
    --bs-gray-600: #7E8299;
    --bs-gray-600-rgb: 126, 130, 153;
    --bs-gray-700: #5E6278;
    --bs-gray-700-rgb: 94, 98, 120;
    --bs-gray-800: #3F4254;
    --bs-gray-800-rgb: 63, 66, 84;
    --bs-gray-900: #181C32;
    --bs-gray-900-rgb: 24, 28, 50;
    --bs-light: #F9F9F9;
    --bs-primary: #7239EA;
    --bs-secondary: #E1E3EA;
    --bs-success: #50cd89;
    --bs-info: #2C9AFF;
    --bs-warning: #E78B2F;
    --bs-danger: #F14C41;
    --bs-dark: #181C32;
    --bs-primary-active: #5014D0;
    --bs-secondary-active: #B5B5C3;
    --bs-light-active: #F4F4F4;
    --bs-success-active: #47be7d;
    --bs-info-active: #2884EF;
    --bs-warning-active: #CE7317;
    --bs-danger-active: #DD4339;
    --bs-dark-active: #131628;
    --bs-primary-light: #F8F5FF;
    --bs-secondary-light: #F9F9F9;
    --bs-success-light: #e8fff3;
    --bs-info-light: #F1F4FF;
    --bs-warning-light: #FCF2E8;
    --bs-danger-light: #FFF2F1;
    --bs-dark-light: #F4F4F4;
    --bs-primary-inverse: #FFFFFF;
    --bs-secondary-inverse: #3F4254;
    --bs-light-inverse: #7E8299;
    --bs-success-inverse: #FFFFFF;
    --bs-info-inverse: #FFFFFF;
    --bs-warning-inverse: #FFFFFF;
    --bs-danger-inverse: #FFFFFF;
    --bs-dark-inverse: #ffffff;
    --bs-light-rgb: 249, 249, 249;
    --bs-primary-rgb: 114, 57, 234;
    --bs-secondary-rgb: 225, 227, 234;
    --bs-success-rgb: 80, 205, 137;
    --bs-info-rgb: 44, 154, 255;
    --bs-warning-rgb: 231, 139, 47;
    --bs-danger-rgb: 241, 76, 65;
    --bs-dark-rgb: 24, 28, 50;
    --bs-text-white: #ffffff;
    --bs-text-primary: #7239EA;
    --bs-text-secondary: #E1E3EA;
    --bs-text-light: #F9F9F9;
    --bs-text-success: #50cd89;
    --bs-text-info: #2C9AFF;
    --bs-text-warning: #E78B2F;
    --bs-text-danger: #F14C41;
    --bs-text-dark: #181C32;
    --bs-text-muted: #A1A5B7;
    --bs-text-gray-100: #F9F9F9;
    --bs-text-gray-200: #F4F4F4;
    --bs-text-gray-300: #E1E3EA;
    --bs-text-gray-400: #B5B5C3;
    --bs-text-gray-500: #A1A5B7;
    --bs-text-gray-600: #7E8299;
    --bs-text-gray-700: #5E6278;
    --bs-text-gray-800: #3F4254;
    --bs-text-gray-900: #181C32;
    --bs-border-color: #F4F4F4;
    --bs-border-dashed-color: #E1E3EA;
    --bs-component-active-color: #FFFFFF;
    --bs-component-active-bg: #7239EA;
    --bs-component-hover-color: #7239EA;
    --bs-component-hover-bg: #F9F9F9;
    --bs-component-checked-color: #FFFFFF;
    --bs-component-checked-bg: #7239EA;
    --bs-box-shadow-xs: 0 0.1rem 0.75rem 0.25rem rgba(0, 0, 0, 0.05);
    --bs-box-shadow-sm: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
    --bs-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
    --bs-box-shadow-lg: 0 1rem 2rem 1rem rgba(0, 0, 0, 0.1);
    --bs-input-bg: var(--bs-body-bg);
    --bs-input-color: var(--bs-gray-700);
    --bs-input-solid-color: var(--bs-gray-700);
    --bs-input-solid-bg: var(--bs-gray-100);
    --bs-input-solid-bg-focus: var(--bs-gray-200);
    --bs-input-solid-placeholder-color: var(--bs-gray-500);
    --bs-tooltip-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    --bs-table-striped-bg: rgba(var(--bs-gray-100-rgb), 0.75);
    --bs-table-loading-message-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    --bs-dropdown-bg: var(--bs-body-bg);
    --bs-dropdown-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    --bs-code-bg: #F1F3F8;
    --bs-code-box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.08);
    --bs-code-color: #b93993;
    --bs-symbol-label-color: var(--bs-gray-800);
    --bs-symbol-label-bg: var(--bs-gray-100);
    --bs-symbol-border-color: rgba(var(--bs-body-bg), 0.5);
    --bs-bullet-bg-color: var(--bs-gray-400);
    --bs-scrolltop-opacity: 0;
    --bs-scrolltop-opacity-on: 0.3;
    --bs-scrolltop-opacity-hover: 1;
    --bs-scrolltop-box-shadow: var(--bs-box-shadow);
    --bs-scrolltop-bg-color: var(--bs-primary);
    --bs-scrolltop-bg-color-hover: var(--bs-primary);
    --bs-scrolltop-icon-color: var(--bs-primary-inverse);
    --bs-scrolltop-icon-color-hover: var(--bs-primary-inverse);
    --bs-drawer-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.05);
    --bs-drawer-bg-color: #ffffff;
    --bs-drawer-overlay-bg-color: rgba(0, 0, 0, 0.2);
    --bs-menu-dropdown-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    --bs-menu-dropdown-bg-color: var(--bs-body-bg);
    --bs-menu-heading-color: #A1A5B7;
    --bs-menu-link-color-hover: #7239EA;
    --bs-menu-link-color-show: #7239EA;
    --bs-menu-link-color-here: #7239EA;
    --bs-menu-link-color-active: #7239EA;
    --bs-menu-link-bg-color-hover: #F9F9F9;
    --bs-menu-link-bg-color-show: #F9F9F9;
    --bs-menu-link-bg-color-here: #F9F9F9;
    --bs-menu-link-bg-color-active: #F9F9F9;
    --bs-scrollbar-color: #F4F4F4;
    --bs-scrollbar-hover-color: #efefef;
    --bs-scrollbar-size: 5px;
    --bs-scrollbar-overlay-size: 19px;
    --bs-scrollbar-overlay-space: 7px;
    --bs-overlay-bg: rgba(0, 0, 0, 0.05);
    --bs-blockui-overlay-bg: rgba(0, 0, 0, 0.05);
    --bs-rating-color-default: #B5B5C3;
    --bs-rating-color-active: #FFAD0F;
    --bs-ribbon-label-box-shadow: 0px -1px 5px 0px rgba(24, 28, 50, 0.1);
    --bs-ribbon-label-bg: #7239EA;
    --bs-ribbon-label-border-color: #4312ab;
    --bs-ribbon-clip-bg: #181C32;
    --bs-engage-btn-bg: #ffffff;
    --bs-engage-btn-box-shadow: 0px 0px 22px #E0E0E0;
    --bs-engage-btn-border-color: #E8E8E8;
    --bs-engage-btn-color: #3F4254;
    --bs-engage-btn-icon-color: #7E8299;
    --bs-engage-btn-color-active: #3F4254;
}

.TableShared {
    background-color: rgb(255 255 255 / 0%);
    color: #fff;
    border: 1px solid #ced4da !important;
    border-radius: 5px !important;
    padding: 10px;
}

.Profiletitle {
    color: #506690;
}

.page_404_color {
    color: white;
    font-weight: 600;
}

.booby2k_wp {
    background-image: url("../../images/booby_2k.jpg");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.page_404_go_home {
    background: rgb(255 255 255 / 30%);
}

    .page_404_go_home:hover {
        background: rgb(255 255 255 / 30%);
    }

.logout:hover {
    background-color: #ededef;
}

.mainMenuUser {
    font-size: 13px;
}

.icon-suffix {
    margin: auto;
}

.head-div.toggled .icon-suffix i::before {
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    content: "\f101";
}

div.baseNavbar {
    top: 0;
    left: 0;
}

form {
    height: auto !important;
}

.itc .item-content {
    display: flex;
}


div.myDocument header {
    background: transparent !important;
}

.accountPictureLetter {
    font-size: 80px;
    font-weight: 500;
    font-family: "Font Awesome 6 Free";
    max-height: 300px;
}

.userImageAddButton:hover {
    opacity: 0.7;
}

.userImageAddButton:focus {
    opacity: 0.7;
}

.baseSidebar-backdrop {
    width: 100vw;
    height: 100vh;
    overflow: inherit;
    align-items: inherit;
}

.container-fluid:has(.baseSidebar-backdrop) {
    flex-wrap: nowrap;
    align-items: initial;
    background-color: rgba(0,0,0,0.5);
    justify-content: unset;
    margin-top: 8px;
}

/*@media (max-width: 1679px) {
    .btn-new {
        height: 70px;
    }
    .btn-rename {
        height: 70px;
    }
    .btn-recycle {
        height: 70px;
    }
    .btn-purge{
        height: 70px;
    }
}*/

.userCharacterStyle {
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}

button.btn-inh {
    border-radius: 0 !important;
    box-shadow: 0 0 0;
    font-size: 1rem !important;
    font-weight: bold !important;
    border: 1px solid #999;
}

    button.btn-inh > span.icon-text {
        font-size: 0.8rem !important;
        font-weight: bold !important;
        color: #181C32 !important;
    }

span.item-content {
    color: #181C32 !important;
}

#searchPDFInput {
    width: 100% !important;
}


.rounded-some {
    border-radius: 10px;
}

span.icon-suffix {
    line-height: 0.6 !important;
}

.hidetopbar {
    overflow: hidden;
}

.modal-white {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0);
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-statistic {
    font-size: 1.5rem;
    word-break: break-all;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.splash-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgb(5, 5, 5, 0.5);
    color: white;
    font-family: Arial, sans-serif;
    flex-direction: column;
    z-index: 10000000;
    position: relative;
}

    .splash-screen h1 {
        font-size: 3rem;
        animation: fadeIn 2s ease-in-out;
    }

    .splash-screen p {
        font-size: 1.5rem;
        animation: fadeIn 2s ease-in-out 1s;
    }

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

input[type='checkbox'] {
    width: 20px; /* Checkbox boyutu */
    height: 20px; /* Checkbox boyutu */
    accent-color: #f50057;
    cursor: pointer; /* İmleç */
    margin-right: 8px; /* Checkbox ile etiket arasındaki boşluk */
}

.App {
    /*overflow-x: auto;*/ /* Yatay kaydırma çubuğu ekler */
    width: 100%; /* Tablonun tam genişliğini almasını sağlar */
}

.form-control:focus {
    border-color: #86b7fe !important;
    box-shadow: 0 0 0 .10rem rgba(13, 110, 253, .25) !important;
}

.listButtonGroup {
    width: inherit;
}

.zrtykLm {
    flex-shrink: 1;
    min-width: 0%;
    position: relative;
    display: flex;
    align-items: end;
    min-width: 350px;
    color: black;
}

.listButtonGroup > .btn {
    width: auto;
}

.krtZuLm {
    min-width: 150px;
    max-width: 150px;
}

.app-sidebar .head-div {
    padding: 8px 12px !important;
}

.sidebar-toggle-button {
    color: black;
    position: absolute;
    left: 95%;
    border: none;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    z-index: 9999999;
}


@media (max-width: 992px) {
    .app-sidebar {
        display: none;
    }

    .app-wrapper.toggled .app-sidebar {
        display: block;
    }

    .buttonGroups {
        position: relative;
        display: block;
        width: 100%;
    }

        .buttonGroups .listButtonGroup {
            margin-bottom: 10px;
        }

    .app-wrapper .tab-content {
        margin: 0.75rem;
    }

    .app-wrapper .container-md > :not(.row) {
        margin-top: 15px;
    }
}

@media (min-width: 992px) {
    .buttonGroups {
        position: relative;
        display: flex;
        width: 100%;
    }
}

.modal-title title-statistic {
    word-break: break-all
}

.modal-header .closeAsbCR .closeButton {
    font-size: 25px;
    margin-left: 10px;
}

.baseNavbar .navbar {
    padding: 0.15rem;
}

.toolbarViewerMiddle {
    width: 25%;
    text-align: center;
    justify-content: center;
}

.fvsVOL {
    border-radius: 0px !important;
}

.czFgKT {
    min-width: 200px;
    height: 200px;
    /*box-shadow: -1px 1px 5px black;*/
    background-color: white;
    border-radius: 15px;
    padding: 15px;
    margin-right: 45px;
    margin-top: 45px;
    margin-bottom: 45px;
    width: 33%;
    transition: border 0.3s ease-in-out;
    border: 2px solid #e7e7e7; /* Başlangıçta şeffaf border */
}

    /* Hover efekti */
    .czFgKT:hover {
        border: 2px solid #28a8e2; /* Hover'da mavi border */
    }

    /* Focus efekti */
    .czFgKT:focus {
        outline: none;
        border: 2px solid #28a8e2; /* Focus'ta mavi border */
    }

    /* Tıklandıktan sonra (active) border renginin kalması */
    .czFgKT:active {
        border: 2px solid #28a8e2; /* Tıklanma sonrası mavi border kalıcı olur */
    }

    /* Eğer tıklamanın ardından sınır renginin sabit kalmasını istersen bir özel sınıf ekleyebilirsin. */
    .czFgKT.selected {
        border: 2px solid #28a8e2;
    }

/* Kutunun içindeki başlık stili */
.tjKLFBm {
    font-weight: 700;
}

/* Fiyatın stili */
.MnVRtY {
    font-weight: 500;
    color: #28a8e2;
    font-size: x-large;
}

/* Buton grubu hover ve focus için */
.toggle-switch {
    text-align: end;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
    }

input:checked + .slider {
    background-color: #4CAF50;
}

    input:checked + .slider:before {
        transform: translateX(26px);
    }

.payment-button {
    background-color: #28a8e2; /* Butonun arka plan rengi */
    color: white; /* Buton yazı rengi */
    border: none; /* Kenar çizgisi yok */
    border-radius: 15px; /* Kenar yuvarlama */
    padding: 10px 20px; /* İç boşluk */
    font-size: 16px; /* Yazı boyutu */
    cursor: pointer; /* Fareyi üzerine getirdiğinde imleci değiştir */
    transition: background-color 0.3s ease; /* Geçiş efekti */
    width: 100%;
}

    .payment-button:hover {
        background-color: #1a7f9c; /* Hover efekti */
    }

    .payment-button:disabled {
        background-color: #b0e0e6; /* Devre dışı bırakıldığında arka plan rengi */
        cursor: not-allowed; /* Devre dışı bırakıldığında imleci değiştirme */
    }

.footerPayments {
    padding-left: 15px;
    padding-right: 15px;
}

.CvGhT {
    font-weight: 700;
    font-size: 25px;
}

.rstPsw {
    align-items: baseline;
}

.required {
    color: red;
}

.control-label {
    color: #444;
}
/* Preview grid */
/* Grid düzeni */
.preview-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    max-height: 200px; /* Maksimum yükseklik */
    overflow-y: auto; /* Dikey kaydırma */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
}

/* Dosya kartlarını daha şık hale getirme */
.preview-item {
    position: relative;
    width: 150px;
    height: 150px;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: 10px;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Görsel önizleme */
.preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Dosya adını daha okunabilir hale getirme */
.file-info {
    position: absolute;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    width: 100%;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* Remove düğmesini daha modern hale getirme */
.remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 3px 8px;
    font-size: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

    .remove-button:hover {
        background-color: #e60000;
    }

.preview-item {
    position: relative;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
}

    .preview-item:hover {
        transform: scale(1.05); /* Hafif büyütme efekti */
    }

.remove-button {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.preview-item:hover .remove-button {
    opacity: 1;
}

@media (max-width: 768px) {
    .preview-item {
        width: 120px;
        height: 120px;
    }

    .remove-button {
        font-size: 8px;
        padding: 2px 5px;
    }
}

@media (max-width: 480px) {
    .preview-item {
        width: 100px;
        height: 100px;
    }

    .file-info {
        font-size: 10px;
    }
}

.buttonDivm {
    width: 100%;
}

.bdEVJx {
    padding: 0px !important;
}
