#registerForm, #account {
    justify-content: center;
    height: max-content;
}

section form {
    padding: 32px;
    padding-bottom: 0px;
    border-bottom: 1px solid #ececec;
    border-left: 1px solid #ededed;
    border-right: 1px solid #efefef;
    border-top: 1px solid #ececec;
    margin-bottom: 1rem;
    //box-shadow: 1px 1px 2px black;
    background: rgba(250,250,250,0.5);
    border-radius: 10px;
}

div.confirm {
    max-width: 450px;
}
/*.btn-primary:hover {
    color: #FFFEFE;
    background-color: #cd8c01;
    border-color: #c18401;
}*/
.btn:hover {
    color: #5E6282;
    text-decoration: none;
}

.PDFDocumentBody {
    //background-image: url("../../images/booby.jpg");
    height: 100%;
}

html, body, form, .page-container, .page-content {
    height: 100%;
}

.AuthTL::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,.9);
}

.AuthTL {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 95%;
    max-height: 95%;
    font-family: url(SFProText-Regular.ttf);
    overflow: auto;
}

#root {
    height: 100%;
    overflow: initial;
    max-height: 100%;
    /*
    overflow: hidden;
    */
}

.rtlkp {
    justify-content: center;
    display: flex;
}

.confirm {
    padding: 32px;
    border-bottom: 1px solid #ececec;
    border-left: 1px solid #ededed;
    border-right: 1px solid #efefef;
    border-top: 1px solid #ececec;
    margin-bottom: 1rem;
    //box-shadow: 1px 1px 2px black;
    background: rgba(250,250,250,0.5);
    margin: 0 auto;
    border-radius: 10px;
}

a {
    color: #28a8e2;
}

/*

nav ul a {
   color: black !important; 
   font-weight: 600;
}
*/

.pro-sidebar-footer a {
    color: var(--bs-gray-600);
}


.min-w-rp {
    min-width: 450px;
}

.error-badge {
    font-size: 16px;
    color: red;
}

.btn-spf-grn {
    background-color: #28a8e2 !important;
    color: white !important;
}

.footer {
    width: 100%;
    background-color: #28a8e2;
    text-align: center;
    padding: 0.5rem;
    margin-top: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1px solid gray;
}

.errMsg {
    display: block;
    font-size: 16px;
    color: red;
}


.btn-spf-grn {
    font-size: 14px;
    font-weight: bold;
}
/*#rstPsw{
    align-items: baseline;
}*/
