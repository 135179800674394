.bg-dark {
    background-color: #28a8e2 !important;
}
.sidebar {
    background: #28a8e2 !important;
}

div.sidebar a {
    color: rgb(40, 168, 226);
}
