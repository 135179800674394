/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff !important;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.btn-secondary {
    color: #fff !important;
}


/*
.baseNavbar {
    position: fixed;
}

*/
#account, #forgetPassword, #registerForm {
    width: 450px
}

@media (max-width: 767px) {
    #account, #forgetPassword, #registerForm {
        width: 100%;
    }
}

* {
    /*font-family: 'Axiforma',Inter, Helvetica, sans-serif;*/
}

label {
    font-size: 0.9625rem;
}

a {
    cursor: pointer;
}

body {
    font-size: .7625rem;
}

.footer {
    border-top: 1px solid #3087af;
    height: 44px;
    padding-bottom: 8px;
    padding-top: 16px;
}

    .footer > span {
    }




/*****/
@media only screen and (min-width: 568px) {
    #account, #forgetPassword, #registerForm, #resetPasswordForm {
        width: 555px;
        padding: 32px 32px 0 32px;
    }
    
}


.dropdown {
    overflow: visible !important;
}

div[data-tag="allowRowEvents"], .btn-group-actions {
    overflow: visible !important;
}

.mobile_w100 {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    margin-top: 25px !important;
    margin-bottom: 25px !important;
}



