footer {
    display: none;
}

.bg-holder, .bg-holder2 {
    opacity: 0.01 !important;
    display: none;
}
/*

body {
    background-color: rgb(237,237,240);
}
*/

/*

div.cpy, section.footers, nav.navbar {
    display: none !important;
}
*/

body, #top {
    margin: 0;
    padding: 0;
}

#canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.canvasp {
    width: 100%;
    height: auto;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 36px;
    left: 0;
    text-align: center;
}

.cvpg {
    box-shadow: 0px 0px 5px gray;
}

.toolbar {
    width: 100%;
    z-index: 101;
    position: relative;
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
    border-bottom: 0.25px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

#toolbarContainer {
    width: inherit;
}

#toolbarViewer {
    width: inherit;
}
/* Toolbar içerisindeki genel düğme düzeni */
.toolbar div, select, input {
    display: flex;
    align-items: center;
}

.toolbarLabel {
    font-size: 13px;
}

body.hidetopbar div.baseNavbar {
    display: none;
}
/*div.toolbar { top:0px; position:fixed;}*/
div.viewer {
    top: 40px !important;
}

span.numPages {
    line-height: 32px;
}

.toolbarField {
    line-height: 32px;
}

div#roadp.roadpshow {
    width: 100% !important;
    height: 100% !important;
    opacity: 0.2 !important;
}

@media (max-width: 992px) {
    #bymmyImage {
        width: 70px;
        margin-right: 0px;
    }

    .leftLongIcon, .leftRightIcon {
        display: none;
    }
}

@media (max-width: 512px) {
    #bymmyImage {
        width: 60px;
        margin-right: 0px;
    }
}

@media (min-width: 512px) {
    #bymmyImage {
        margin-right: -10px;
    }
}



@media (min-width: 992px) {
    #bymmyImage {
        width: 90px;
    }
}
/*#toolbarViewerLeft {
    width: 100%;
}*/
/* Her iki tarafın dengelenmesi */
#toolbarViewerLeft {
    display: flex;
    flex: 1;
}

#toolbarViewerRight {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.leftLongIcon, .leftRightIcon {
    position: fixed;
    width: 50px; /* Buton genişliği */
    height: 50px; /* Buton yüksekliği */
    top: 50%;
    transform: translateY(-50%);
}

.leftLongIcon {
    left: 20%; /* Soldaki butonun konumu */
}

.leftRightIcon {
    right: 20%; /* Sağdaki butonun konumu */
}
/* Buton stilleri */
.lftLoB {
    background-color: #f0f0f0; /* Arka plan rengi */
    border: 2px solid #ddd; /* Dış kenar */
    border-radius: 50%; /* Yuvarlak buton */
    width: 50px; /* Buton boyutu */
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Hafif gölge */
    transition: all 0.3s ease; /* Geçiş efektleri */
    cursor: pointer;
}

    .lftLoB:hover {
        background-color: #e0e0e0; /* Hover'da arka plan değişikliği */
        box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15); /* Hover'da gölge büyütme */
        transform: scale(1.1); /* Hafif büyüme efekti */
    }

    /* Icon boyutlandırma */
    .lftLoB svg {
        width: 24px; /* Icon genişliği */
        height: 24px; /* Icon yüksekliği */
        color: #333; /* Icon rengi */
        transition: color 0.3s ease;
    }

    .lftLoB:hover svg {
        color: #000; /* Hover sırasında icon rengini değiştir */
    }

/* İkonlar */
.toolbar svg {
    color: #333;
    transition: color 0.2s ease, transform 0.2s ease;
}

.toolbar div:hover > svg,
.toolbar div:focus > svg {
    color: #007bff; /* Hover veya focus durumunda ikon rengini değiştir */
    transform: scale(1.1); /* İkona hafif büyüme efekti */
}

.toolbar div:hover {
    background-color: transparent; /* Arka plan rengi transparent olacak */
}


/* Input ve select alanları */
.toolbarField {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    transition: border-color 0.2s ease, background-color 0.2s ease;
}

    .toolbarField:focus {
        border-color: #007bff;
        background-color: #fff;
        outline: none;
    }

/* Zoom butonları */
.splitToolbarButton div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    transition: background-color 0.2s ease;
}

    .splitToolbarButton div:hover {
        background-color: #e0e0e0;
        border-radius: 50%;
    }

/* Scale dropdown */
#scaleSelect {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f6f6f6;
    cursor: pointer;
}

    #scaleSelect:hover {
        background-color: #e0e0e0;
        border-color: #007bff;
    }

/* Responsive tasarım */
@media screen and (max-width: 768px) {
    .hiddenMediumView {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    .hiddenSmallView {
        display: none;
    }
}
