
@media (max-width: 992px) {
    .app-sidebar {
        width: 100%;
        /*height: 370px;*/
        position: initial;
    }

        .app-sidebar .head-div {
            padding: 10px !important;
            padding-left: 30px !important;
        }

        .app-sidebar .pro-sidebar-content {
            padding-top: 0px;
        }

            .app-sidebar .pro-sidebar-content .pro-menu {
                padding-top: 2px;
                padding-bottom: 2px;
            }

        .app-sidebar .head-div .head-text img {
            width: 105px;
        }

    .sidebar-footer-padding {
        padding-bottom: 0px !important;
    }

    .app-sidebar .pro-sidebar {
        width: 100%;
    }

    .app-sidebar .head-div .icon-suffix {
        display: none;
    }

    .mainDivRsT {
        display: block;
    }

    #root {
        overflow: auto;
    }

    .modal .modal-content {
        /*width: auto !important;*/
        width: 90%;
    }

    .statisticNumbers {
        font-size: 1rem !important;
    }

    .modal-header .closeAsbCR .closeButton {
        /*padding-bottom: 75px;*/
    }

    #sharePage .col-md-6 .shareTable {
        margin-bottom: 75px;
    }
}

@media (min-width:410px) {

    .btn-group button {
        min-width: 110px;
    }
}

@media (max-width:768px) {

    .row .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    /*.echarts-for-react {
        width: calc(100% - 80px);
    }*/
}

@media (max-width:409px) {

    .btn-group button {
        min-width: 70px;
        /*height: 70px;*/
    }
}

@media (max-width:500px) {

    #toolbarViewer {
        font-size: 8px;
        padding: 0.15rem !important;
    }

    div#toolbarContainer {
        height: 40px;
    }

    .pipe {
        margin-right: 0.05rem !important;
        margin-left: 0.05rem !important;
    }

    #zoomOut {
        margin-left: 0.10rem;
        margin-right: 0.10rem;
    }

    #zoomIn {
        margin-left: 0.10rem;
        margin-right: 0.10rem;
    }

    .echarts-for-react {
        max-height: 200px;
        max-width: 500px;
    }
}

@media (min-width:501px) {

    div#toolbarContainer {
        height: 46px;
    }

    .pipe {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }

    #zoomOut {
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }

    #zoomIn {
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }
}
